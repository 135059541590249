import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ComposicaoValorService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor`, dados, config);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10, sort: 'id,asc' }) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/buscar-com-paginacao`, { params });
    }

    buscarPorId(id) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/${id}`);
    }

    buscarIndicadoresPrincipais(composicaoValorId) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/buscar-indicadores-principais/${ composicaoValorId }`);
    }

    buscarPorIdOuDescricao(idOuDescricao, fixoDolar, params = { situacao: "ATIVO", page: 0, size: 50, sort: 'id,asc' }) {
        params["filtro"] = idOuDescricao;
        params["fixoDolar"] = fixoDolar;
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/buscar-por/id-ou-descricao`, { params });
    }

    inativar(id) {
        return axios.put(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/inativar/${id}`);
    }

    ativar(id) {
        return axios.put(`${ApiUrl.vg}api/genesis-vg/v1/composicao-valor/ativar/${id}`);
    }

    buscarOperacoesEnum() {
        return [
            {
                codigo: "REFERENCIA",
                descricao: "Referência",
                severity: "info",
                title:"Referência",
            },
            {
                codigo: "COTACAO",
                descricao: "Cotação",
                severity: "info",
                title:"Cotação",
            },
            {
                codigo: "MULTIPLICACAO",
                descricao: "*",
                severity:"success",
                title:"Multiplicar",
            },
            {
                codigo: "DIVISAO",
                descricao: "/",
                severity:"success",
                title:"Dividir",
            },
            {
                codigo: "ADICAO",
                descricao: "+",
                severity:"success",
                title:"Somar",
            },
            {
                codigo: "SUBTRACAO",
                descricao: "-",
                severity:"success",
                title:"Subtrair",
            }
        ];
    }

    buscarFinalidadesEnum() {
        return [
            {
                codigo: "PRECO_CONTRATO",
                descricao: "Preço Contrato"
            },
            {
                codigo: "OUTROS",
                descricao: "Outros"
            }
        ];
    }
}

export default new ComposicaoValorService();
