<template>
    <DataTable editMode="row" v-model:editingRows="editingRows" responsiveLayout="stack" :value="dto.itens"
        selectionMode="single" showGridlines v-model:selection="itemSelecionado">
        <template #header>
            Itens da Regra
        </template>

        <template #empty>
            Nenhum registro encontrado.
        </template>

        <template #loading>
            Carregando... Por favor, aguarde.
        </template>

        <Column v-if="!editavel" field="id" header="Código">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>

        <Column field="ordem">
            <template #header>
                <strong>Ordem<span v-if="editavel" style="color:red;">&nbsp;*</span></strong>
            </template>
            <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" :min="0" :max="1000" :class="{ 'p-invalid': campoInvalido.ordem }"
                    class="w-full" />
            </template>
        </Column>

        <Column field="indicadorValor">
            <template #header>
                <strong>Variável (Indicador)<span v-if="editavel" style="color:red;">&nbsp;*</span></strong>
            </template>
            <template #body="{ data, field }">
                {{ data[field]?.descricao }}
            </template>
            <template #editor="{ data, field }">
                <AutoComplete placeholder="Selecione..." dropdown v-model="data[field]" field="descricao" class="w-full"
                    :suggestions="indicadorFiltro" :forceSelection="true"
                    :class="{ 'p-invalid': campoInvalido.indicadorValor }" @complete="buscarIndicador" />
            </template>
        </Column>

        <Column field="operacao" class="centralizar-titulo-tabela" bodyClass="text-center">
            <template #header>
                <strong>Operação<span v-if="editavel" style="color:red;">&nbsp;*</span></strong>
            </template>
            <template #body="{ data, field }">
                <Tag :value="data[field]?.descricao" :severity="data[field]?.severity" v-if="data[field]"
                    :title="data[field]?.title" />
            </template>
            <template #editor="{ data, field }">
                <AutoComplete placeholder="Selecione..." dropdown v-model="data[field]" field="descricao" class="w-full"
                    :suggestions="operacaoFiltro" :forceSelection="true" :class="{ 'p-invalid': campoInvalido.operacao }"
                    @complete="buscarOperacao" />
            </template>
        </Column>

        <Column field="referencia" header="Variável (Referência)">
            <template #body="{ data, field }">
                {{ data[field]?.descricao }}
            </template>

            <template #editor="{ data, field }">
                <AutoComplete placeholder="Selecione..." dropdown v-model="data[field]" field="descricao" class="w-full"
                    :suggestions="indicadorFiltro" :forceSelection="true"
                    :class="{ 'p-invalid': campoInvalido.indicadorValor }" @complete="buscarIndicador"
                    @item-select="indicadorSelecionadoAction(data)" />
            </template>
        </Column>

        <Column field="valorPadrao" header="Valor Padrão">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field], 2, 2) }}
            </template>

            <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" class="w-full" locale="pt-BR" :max="9999999" :minFractionDigits="2"
                    :maxFractionDigits="2" />
            </template>
        </Column>

        <Column field="altera" header="Altera" dataType="boolean" class="centralizar-titulo-tabela" bodyClass="text-center">
            <template #body="{ data, index }" font-size: 20px;>
                <i v-if="data.altera" class="pi pi-check-circle" style="color: green; font-size: 20px;" title="Sim"></i>
                <i v-if="data.indicadorValor && !data.altera" class="pi pi-times-circle" style="color: red; font-size: 20px;" title="Não"></i>
            </template>
            <template #editor="{ data, field }">
                <Checkbox v-if="data.indicadorValor && data.indicadorValor.aFixar" v-model="data[field]" :binary="true" />
                <i v-if="data.indicadorValor && !data.indicadorValor.aFixar" class="pi pi-times-circle" style="color: red; font-size: 20px;" title="Não"></i>
            </template>
        </Column>

        <Column field="indicadorValor" header="A Fixar" dataType="boolean" class="centralizar-titulo-tabela"
            bodyClass="text-center">
            <template #body="{ data, field }" font-size: 20px;>
                <i v-if="data[field] && data[field].aFixar" class="pi pi-check-circle" style="color: green; font-size: 20px;" title="Sim"></i>
                <i v-if="data[field] && !data[field].aFixar" class="pi pi-times-circle" style="color: red; font-size: 20px;" title="Não"></i>
            </template>
        </Column>

        <Column v-if="editavel" header="Ações" style="width: 9rem;" class="centralizar-titulo-tabela">
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-plus" aria-label="Novo"
                        @click="editar(data)" v-if="!data.indicadorValor" title="Novo"></Button>
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-pencil" aria-label="Editar"
                        @click="editar(data)" v-if="data.indicadorValor" title="Editar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-trash" aria-label="Excluir"
                        @click="excluir(index)" v-if="data.indicadorValor" title="Excluir"></Button>
                </div>
            </template>
            <template #editor="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button class="p-button-rounded p-button-info flex-none mr-2" icon="pi pi-save" aria-label="Editar"
                        @click="salvar(data, index)" title="Salvar"></Button>
                    <Button class="p-button-rounded p-button-danger flex-none mr-2" icon="pi pi-times" aria-label="Editar"
                        @click="cancelar()" title="Cancelar"></Button>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import ManutencaoIndicadoresSerivce from '../../../service/ManutencaoIndicadoresSerivce';
import ComposicaoValorService from '../../../service/ComposicaoValorService';
import Formatacao from '../../../utilities/Formatacao';

export default {
    props: {
        editavel: {
            type: Boolean,
            required: true
        },

        dto: {
            type: Object,
            required: true
        },

        campoInvalido: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            editingRows: [],

            indicadorFiltro: [],
            indicadores: [],

            operacaoFiltro: [],
            operacoes: [],

            itemSelecionado: {},
        }
    },

    created() {
        this.carregarIndicadores();
        this.operacoes = ComposicaoValorService.buscarOperacoesEnum();
        this.setup();
    },

    updated() {
        this.setup();
    },

    methods: {

        setup() {
            if (!this.dto.itens) {
                this.dto.itens = [];
            }

            if (this.editavel) this.adicionarLinha();
        },

        editar(registro) {
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        validarCamposObrigatorios(registro) {
            const campos = [];

            if (!registro.ordem) {
                campos.push("Ordem");
                this.campoInvalido.ordem = true;
            }
            else {
                this.campoInvalido.ordem = false;
            }

            if (!registro.indicadorValor) {
                campos.push("Indicador");
                this.campoInvalido.indicadorValor = true;
            }
            else {
                this.campoInvalido.indicadorValor = false;
            }

            if (!registro.operacao) {
                campos.push("Operação");
                this.campoInvalido.operacao = true;
            }
            else {
                this.campoInvalido.operacao = false;
            }

            if (campos.length > 0) {
                const toast = {
                    severity: 'warn',
                    summary: 'Item de Composição de Valor',
                    detail: `Por favor, preencher o campo ${campos[0]}`,
                    life: 7500
                }

                if (campos.length > 1) {
                    toast.detail = "Por favor, preencher os campos: ";
                    toast.detail += campos.join(", ");

                    const indice = toast.detail.lastIndexOf(", ");
                    toast.detail = `${toast.detail.substring(0, indice)} e ${toast.detail.substring(indice + 1, toast.detail.length)}.`;
                }

                this.$toast.add(toast);
                return true;
            }

            return false;
        },

        validarOrdem(ordem) {
            this.dto.itens.array.forEach(item => {
                if (item.ordem == ordem) {
                    const toast = {
                        severity: 'warn',
                        summary: 'Item de Composição de Valor',
                        detail: `Por favor, preencher o campo ${campos[0]}`,
                        life: 7500
                    }

                    if (campos.length > 1) {
                        toast.detail = "Por favor, preencher os campos: ";
                        toast.detail += campos.join(", ");

                        const indice = toast.detail.lastIndexOf(", ");
                        toast.detail = `${toast.detail.substring(0, indice)} e ${toast.detail.substring(indice + 1, toast.detail.length)}.`;
                    }

                    this.$toast.add(toast);
                    return true;
                }
            });
            return false;
        },

        salvar(registro, indice) {
            if (!registro) return;
            if (this.validarCamposObrigatorios(registro)) return;
            this.dto.itens[indice] = registro;
            this.dto.itens.sort((objA, objB) => { return objA["ordem"] - objB["ordem"]; });
            this.editingRows = [];
            this.adicionarLinha();
        },

        excluir(indice) {
            this.dto.itens.splice(indice, 1);
            this.adicionarLinha();
        },

        cancelar() {
            if (!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.adicionarLinha();
            this.campoInvalido.indicadorValor = false;
            this.campoInvalido.operacao = false;
            this.campoInvalido.ordem = false;
        },

        adicionarLinha() {
            const indice = this.dto.itens.findIndex(item => {
                return !item.ordem || !item.indicadorValor || !item.operacao;
            });

            if (indice < 0) {
                this.dto.itens.push({});
            }
        },

        carregarIndicadores() {
            const filtro = {};
            filtro["page"] = 0;
            filtro["size"] = 1000;
            filtro["sort"] = "id,desc";

            ManutencaoIndicadoresSerivce.buscarComPaginacao(filtro)
                .then(({ data }) => {
                    if (data) {
                        this.indicadores = data.content;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao buscar indicadores',
                        detail: `Falha não mapeada.`,
                        life: 7500
                    };

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        buscarIndicador(event) {
            if (!event.query.trim().length) {
                this.indicadorFiltro = [...this.indicadores];
            } else {
                this.indicadorFiltro = this.indicadores.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarOperacao(event) {
            if (!event.query.trim().length) {
                this.operacaoFiltro = [...this.operacoes];
            } else {
                this.operacaoFiltro = this.operacoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            if (!!value) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },
    }
}
</script>

<style>
.centralizar-titulo-tabela {
    text-align: center !important;
}

.centralizar-titulo-tabela .p-column-header-content {
    display: inline;
}
</style>
